import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { IMetricsResponseDataModel } from '../../../data-models/metrics.data-model';

export function toCompanyDetailsVM(
  company: ICompanyDataModel,
  companyMetrics: IMetricsResponseDataModel | null
) {
  return {
    ...company,
    _calculatedValues: {
      impliedEquityValue: companyMetrics?.metrics.at(0)?.impliedEquityValue ?? null,
    },
  };
}
export type CompanyDetailsPanelViewModel = ReturnType<typeof toCompanyDetailsVM>;

export function fromCompanyDetailsVM(
  viewModel: Partial<CompanyDetailsPanelViewModel>
): Partial<ICompanyDataModel> {
  const { _calculatedValues, ...company } = viewModel;

  return company;
}
