import { ISelectMeta, PrimitiveType } from '../data-models/field3.data-model';
import { IFormatterDataModel } from '../data-models/formatter.data-model';
import { RendererType } from '../data-models/field.data-model';
import { FormatterFn, StandardFormatterId } from '../util/formatter-service';

export interface IDisplayField<MetaType> {
  dataType?: PrimitiveType;
  description?: string;
  isCalculated?: boolean;
  key: string;
  label?: string;
  formatter?: string | StandardFormatterId | IFormatterDataModel<unknown>;
  renderer: RendererType;
  rendererMeta?: MetaType;
}

export function getSelectFormatter<T>(field: IDisplayField<T>): FormatterFn<string> {
  const values = (field.rendererMeta as ISelectMeta<string>)?.values ?? [];
  const valueToLabelMap = values.reduce(
    (res, selectItem) => {
      res[selectItem.value] = selectItem.displayName ?? selectItem.value;
      return res;
    },
    {} as Record<string, string>
  );

  return (value: string) => {
    return valueToLabelMap[value] ?? '';
  };
}

export function getMultiSelectFormatter<T>(field: IDisplayField<T>): FormatterFn<string[]> {
  const baseFormatter = getSelectFormatter(field);

  return (values: string[]) => {
    return values.map((value) => baseFormatter(value)).join(', ');
  };
}
