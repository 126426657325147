import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { FieldPath } from 'react-hook-form';
import { field2ToFormField, FieldEntity } from '../../../data-models/field2.data-model';
import { customFieldsByEntity } from '../../../services/state/AdminPanel/CustomFieldsState';
import { schemaToFormFields } from '../../../util/schema-utils';
import {
  CompanyFundMetricsDataModel,
  CompanyFundMetricsField,
  companyFundMetricsFields,
} from '../../../schemas/CompanyFundMetrics.schema';
import { filterAndSortByPreferences } from '../utils/filterAndSortByPreferences';
import { IFormField } from '../../../view-models/form.view-model';

export const AllFundsId = -1;

export function useInvestmentMetricsFields(
  fieldsToShow?: FieldPath<CompanyFundMetricsDataModel>[],
  selectedFund?: number
) {
  const allFields = useAllInvestmentMetricsFields(selectedFund);
  return useMemo(() => {
    const res = !fieldsToShow?.length ? allFields : filterAndSortByPreferences(allFields, fieldsToShow);
    setDisabledFields(res, selectedFund);

    return res;
  }, [allFields, fieldsToShow, selectedFund]);
}

export function useAllInvestmentMetricsFields(selectedFund?: number) {
  const customFields = useRecoilValue(customFieldsByEntity);

  return useMemo(() => {
    if (selectedFund === AllFundsId) {
      const baseSchema = yup
        .object(companyFundMetricsFields())
        .pick(['moic', 'ownerShipPercentage', 'irr', 'amountInvested', 'fmv', 'distributions']);

      return schemaToFormFields(baseSchema);
    } else {
      const baseSchema = yup
        .object(companyFundMetricsFields())
        .pick([
          'moic',
          'ownerShipPercentage',
          'irr',
          'amountInvested',
          'fmv',
          'reserves',
          'exitDate',
          'exitValuation',
          'exitType',
          'initialPreMoney',
          'exitOwnershipPercentage',
          'entryOwnership',
          'distributions',
          'convertedOwnership',
          'percentOfFund',
          'rtfe',
        ]);
      const companyCustomFields =
        customFields.get(FieldEntity.fundCompany)?.map((field) => field2ToFormField(field)) ?? [];
      const fundCompanyFields = schemaToFormFields(baseSchema);

      return fundCompanyFields.concat(companyCustomFields);
    }
  }, [customFields, selectedFund]);
}

function setDisabledFields(fields: IFormField<unknown>[], selectedFund?: number) {
  if (selectedFund === AllFundsId) {
    fields.forEach((field) => {
      field.disabled = true;
    });
  } else {
    const disabledFields: Set<CompanyFundMetricsField> = new Set([
      'moic',
      'ownerShipPercentage',
      'irr',
      'fmv',
      'distributions',
      'amountInvested',
      'convertedOwnership',
      'percentOfFund',
      'rtfe',
    ]);

    fields.forEach((field) => {
      field.disabled = disabledFields.has(field.key as CompanyFundMetricsField);
    });
  }
}
