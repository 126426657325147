import { css } from '@emotion/react';
import { CSSProperties } from 'react';
import { colors } from './colors';

export const transparentBgStyle: CSSProperties = {
  background:
    'radial-gradient(100% 204.54% at 0% 0%, rgba(249, 250, 250, 0.8) 0%, rgba(255, 255, 255, 0.32) 100%)',
};

export const cardBorderStyles: CSSProperties = {
  boxShadow:
    '0px 24px 48px 0px rgba(75, 82, 93, 0.02), 0px 4px 24px 0px rgba(16, 37, 62, 0.04), 0px -4px 40px 0px rgba(255, 255, 255, 0.06)',
  borderRadius: '4px',
  border: `1px solid ${colors.neutral[80]}`,
};

export const cardStyles: CSSProperties = {
  ...cardBorderStyles,
  ...transparentBgStyle,
  padding: '0.5rem 1rem',
};

export const borderStyle = {
  borderRadius: '4px',
  border: `1px solid ${colors.neutral[80]}`,
};

export const flatCardStyleNewCss = css({
  ...borderStyle,
  padding: '0.5rem 1rem',
});

export const elevatedCardStyleCss = css({
  ...borderStyle,
  boxShadow:
    '0px 24px 48px 0px rgba(75, 82, 93, 0.02), 0px 4px 24px 0px rgba(16, 37, 62, 0.04), 0px -4px 40px 0px rgba(255, 255, 255, 0.06)',
  padding: '0.5rem 1rem',
});

export const baseFormInlineClassNames = 'form-control form-inline';
export const formSelectInlineClassNames = `${baseFormInlineClassNames} form-inline-select`;
export const formTextFieldInlineClassNames = `${baseFormInlineClassNames} form-inline-input`;
