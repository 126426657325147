import { css } from '@emotion/react';
import { Divider, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { INewsDataModel } from '../../../../data-models/news.data-model';
import { colors } from '../../../../theme/colors';
import { elevatedCardStyleCss } from '../../../../theme/component-styles';
import { getNewsTime } from '../../utils/getNewsTime';

type Props = {
  news: INewsDataModel;
};

const dot = css`
  width: 0.4rem;
  height: 0.4rem;
  background-color: ${colors.secondary[40]};
  border-radius: 50%;
  align-self: center;
`;

const newsLink = css`
  &:hover {
    text-decoration-color: ${colors.secondary[20]};
  }
`;

export const NewsRow: FC<Props> = ({ news }) => {
  const { title, date, author, publisher, sentence, url } = news;
  const time = date ? getNewsTime(new Date(date)) : '';

  return (
    <Typography color='text.primary' component='div' css={elevatedCardStyleCss}>
      <Typography variant='overline' lineHeight={'1.5rem'}>
        {publisher}
      </Typography>
      <Link
        variant='subtitle2'
        color='text.primary'
        href={url}
        target='_blank'
        underline='hover'
        css={newsLink}
      >
        {title}
      </Link>
      <Typography variant='caption'>{sentence}</Typography>
      <>
        <Typography variant='caption' color={'text.secondary'} display={'flex'} gap='0.25rem'>
          {time && time}
          {author && (
            <>
              <Divider orientation='vertical' css={dot} />
              {author}
            </>
          )}
        </Typography>
      </>
    </Typography>
  );
};
