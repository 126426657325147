import { useMemo } from 'react';
import * as yup from 'yup';
import { useRecoilValue } from 'recoil';
import { FieldPath } from 'react-hook-form';
import { FieldEntity, field2ToFormField } from '../../../data-models/field2.data-model';
import { customFieldsByEntity } from '../../../services/state/AdminPanel/CustomFieldsState';
import { useSchemaToFormFields } from '../../../util/schema-utils';
import { useUserIdField } from '../../../data-fields/CommonFields';
import { CompanySchema } from '../../../schemas/Company.schema';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { filterAndSortByPreferences } from '../utils/filterAndSortByPreferences';

export function useAllCompanyDetailsFields() {
  const companyDetailsSchema = useCompanyDetailsPanelSchema();
  const customFields = useRecoilValue(customFieldsByEntity);
  const schemaToFormFields = useSchemaToFormFields();

  return useMemo(() => {
    const companyCustomFields =
      customFields.get(FieldEntity.company)?.map((field) => field2ToFormField(field)) ?? [];
    const companyFields = schemaToFormFields(companyDetailsSchema);
    return companyFields.concat(companyCustomFields);
  }, [companyDetailsSchema, customFields, schemaToFormFields]);
}

export function useCompanyDetailsFields(fieldsToShow?: FieldPath<ICompanyDataModel>[]) {
  const allDetailsFields = useAllCompanyDetailsFields();

  return useMemo(() => {
    if (!fieldsToShow?.length) return allDetailsFields;
    return filterAndSortByPreferences(allDetailsFields, fieldsToShow);
  }, [allDetailsFields, fieldsToShow]);
}

function useCompanyDetailsPanelSchema() {
  const {
    acquirer,
    boardDirectorId,
    dealLeadId,
    dealTeamIds,
    internalSourceId,
    boardStatus,
    impliedEquityValue,
    legalName,
  } = CompanySchema.base();

  return yup.object({
    acquirer,
    boardStatus,
    legalName,
    impliedEquityValue: impliedEquityValue.formMeta({
      isCalculated: true,
    }),
    internalSourceId: internalSourceId.formMeta(
      useUserIdField({
        key: 'internalSourceId',
        label: 'Internal Source',
      })
    ),
    dealLeadId: dealLeadId.formMeta(
      useUserIdField({
        key: 'dealLeadId',
        label: 'Deal Lead',
      })
    ),
    boardDirectorId: boardDirectorId.formMeta(
      useUserIdField({
        key: 'boardDirectorId',
        label: 'Board Director',
      })
    ),
    dealTeamIds: dealTeamIds.formMeta(
      useUserIdField({
        key: 'dealTeamIds',
        label: 'Deal Team',
        multi: true,
      })
    ),
  });
}
