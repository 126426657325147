import { Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { KeyboardEvent, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { colors } from '../../../../theme/colors';
import { FMT } from '../../../../util/formatter-service';
import { IFormField } from '../../../../view-models/form.view-model';
import { FieldLabel } from '../../FormComponents';
import { IBaseFieldProps } from '../types';
import { FieldWithOverrideModal } from './FieldWithOverrideModal';

const baseCss = css`
  border-radius: 0.25rem;
  display: grid;
  grid-template-rows: min-content min-content;
  height: 100%;
  margin: -0.5rem;
  padding: 0.5rem;
  row-gap: 0.5rem;
  width: 100%;

  & .fake-input {
    padding: 0.5rem 0.25rem;
    margin-left: -0.25rem;
    margin-top: -0.5rem;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: border-color 0.25s;
  }
`;

const hoverCss = css`
  &:hover,
  &:focus {
    & .fake-input {
      border: 1px solid ${colors.neutral[40]};
    }
  }
`;

const wrapperCss = (disabled: boolean = false) => {
  return disabled ? [baseCss] : [baseCss, hoverCss];
};

export interface IDispatchEvent<T> {
  data: T;
  type: string;
}
export interface IFieldWithModalProps<ValueType, FormItemType = unknown>
  extends IBaseFieldProps<FormItemType> {
  calculatedValue: ValueType | null;
}

export function FieldWithOverrideBase<T>(props: IFieldWithModalProps<T>) {
  const { formField, formProps } = props;
  const { onChange: formOnChange } = formProps;
  const { disabled, label } = formField;
  const format = FMT.get().getFormatterForFormField(formField);
  const isOverridden = formProps.value !== null && formProps.value !== (props.calculatedValue as number);

  const tooltipText = isOverridden
    ? `This calculated field has been overridden, click to edit or clear override`
    : `This calculated field can be overridden, click to edit`;

  const [showModal, setModalIsOpen] = useState(false);

  const formattedCurrentValue = useMemo(() => {
    return isOverridden ? format(formProps.value) : format(props.calculatedValue);
  }, [formProps.value, format, isOverridden, props.calculatedValue]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setModalIsOpen(true);
    }
  };

  const onClose = (value: T | null | undefined) => {
    if (value !== undefined) {
      formOnChange(value);
    }
    setModalIsOpen(false);
  };

  return (
    <>
      {showModal && (
        <FieldWithOverrideModal
          calculatedValue={props.calculatedValue}
          formField={formField}
          initialValue={isOverridden ? formProps.value : null}
          onClose={onClose}
        />
      )}
      <div
        aria-label={label}
        css={wrapperCss(disabled)}
        onKeyDown={handleKeyDown}
        onClick={() => {
          setModalIsOpen(true);
        }}
        role={'button'}
        tabIndex={0}
        title={tooltipText}
      >
        <FieldLabel field={{ ...formField, isOverridden }} />
        <div className={'fake-input'}>
          <Typography variant={'body2'}>{formattedCurrentValue}</Typography>
        </div>
      </div>
    </>
  );
}

export function FieldWithOverride(props: { formField: IFormField<unknown> }) {
  const { formField } = props;
  const { getValues } = useFormContext();

  return (
    <Controller
      name={formField.key}
      render={({ field: reactHookField }) => {
        const calculatedValue = getValues(`_calculatedValues.${formField.key}`);

        return (
          <FieldWithOverrideBase
            calculatedValue={calculatedValue ?? null}
            formField={formField}
            formProps={reactHookField}
          />
        );
      }}
    />
  );
}
